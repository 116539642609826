import { useState, useEffect, useRef } from "react"
import ChatWindow from "../ChatWindow"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { TypingMessage } from "./Enums"
import { setMessageHistory, setPartyMessageHistory, setUnityMessage } from "../../../store/messages"
import { setPartyUsers, User } from "../../../store/usersOnline"
import { cleanMessage, Reaction } from "./ChatBubble"
import { addHost, addMessage, addPartyMessage, addPartyPeople, removeDuplicatesByPlayerId } from "./UtilityFunctions"
import { setPartyChat, setPartyInfo } from "../../../store/party"
import { PartyPerson, PartyPersonJoinStatus } from "../partyComponents/PartyTypes"
import { setIsInParty, setUpdate } from "../../../store/appUser"
import { getTimestamp } from "../Timestamp"


type TypingUsers = {
  playerId: string,
  user: string,
  timestamp: string
}

type Props = {
  setIsFocused: Function,
  room: string,
  getAvatar: Function,
  newMessage: Function
}

export const SubscriptionHandler: React.FC<Props> = ({ setIsFocused, room, getAvatar, newMessage }) => {

  const { id, update } = useAppSelector(state => state.appUser)
  const { messageHistory, partyMessageHistory } = useAppSelector((state) => state.messages)
  const { partyUsers, users } = useAppSelector((state) => state.usersOnline)
  const [isTyping, setIsTyping] = useState("")
  const [isPartyTyping, setIsPartyTyping] = useState("")
  const [typingUsers, setTypingUsers] = useState<TypingUsers[]>([])
  const [partyTypingUsers, setPartyTypingUsers] = useState<TypingUsers[]>([])
  const typingUsersRef = useRef(typingUsers)
  const partyTypingUsersRef = useRef(partyTypingUsers)
  const playerIdReF = useRef(id)
  const messageRef = useRef(messageHistory)
  const partyMessageRef = useRef(partyMessageHistory)
  const usersRef = useRef(users)
  const partyUsersRef = useRef(partyUsers)
  const dispatch = useAppDispatch()


  useEffect(() => {
    typingUsersRef.current = typingUsers
    playerIdReF.current = id
    messageRef.current = messageHistory
    partyMessageRef.current = partyMessageHistory
    usersRef.current = users
    partyUsersRef.current = partyUsers
    partyTypingUsersRef.current = partyTypingUsers
  }, [typingUsers, partyTypingUsers, playerIdReF, messageHistory, partyMessageHistory, users, partyUsers])

  // Chat Subscription Handler
  useEffect(() => {
    window.STOMP.subscribe("/topic/chat", (frame: any) => {
      handleWebsocketMessages(frame, "global")
    });
    window.STOMP.subscribe("/topic/chat/" + room, (frame: any) => {
      handleWebsocketMessages(frame, "room")
    });
    window.STOMP.subscribe("/user/queue/party", (frame: any) => {
      handleUserQueuePartyMessage(frame)
    })

    window.STOMP.subscribe("/topic/reaction/" + room, (frame: any) => {
      console.log(frame)
      handleReactions(frame)
    })
    const intervalId = setInterval(() => {
      const now = new Date(getTimestamp()).getTime();
      let currentTypingUsers = [...typingUsersRef.current];
      currentTypingUsers = currentTypingUsers.filter(userObj => {
        const userTimestamp = new Date(userObj.timestamp).getTime();
        return (now - userTimestamp) <= 800;
      });
      setTypingUsers(currentTypingUsers);
      setIsTyping(setTyping(typingUsersRef.current))
    }, 1000);

    const intervalIdParty = setInterval(() => {
      const now = new Date(getTimestamp()).getTime();
      let currentTypingUsers = [...partyTypingUsersRef.current];
      currentTypingUsers = currentTypingUsers.filter(userObj => {
        const userTimestamp = new Date(userObj.timestamp).getTime();
        return (now - userTimestamp) <= 800;
      });
      setPartyTypingUsers(currentTypingUsers);
      setIsPartyTyping(setTyping(partyTypingUsersRef.current))
    }, 1000);
  }, [])

  function handleReactions(frame: any){
    let currentMessages: Array<any> = [...messageRef.current];
    const messageIndex = currentMessages.findIndex(message => message.id === frame.body[0].messageId);

    if (messageIndex !== -1) {
        const message = { ...currentMessages[messageIndex] };
        const reactions = [...message.reactions];
        const existingReactionIndex = reactions.findIndex(reaction => reaction.playerId === frame.body[0].playerId);

        if (existingReactionIndex !== -1) {
            reactions[existingReactionIndex] = {
                ...reactions[existingReactionIndex],
                emoji: frame.body[0].emoji,
            };
        } else {
            const newReaction: Reaction = {
                playerId: frame.body[0].playerId,
                displayName: frame.body[0].displayName,
                emoji: frame.body[0].emoji,
            };
            reactions.push(newReaction);
        }
        message.reactions = reactions;
        currentMessages[messageIndex] = message;
        dispatch(setMessageHistory(currentMessages))
  }
}

  //handle websocket messages
  function handleWebsocketMessages(frame: any, from: string) {

    if (frame.body.body === TypingMessage.IS_TYPING) {
      if (frame.body.playerId !== playerIdReF.current) {
        setIsTyping(handleIsTyping(frame.body.from, frame.body.timestamp, frame.body.playerId, typingUsersRef.current, false))
      }
    } else if (frame.body.body === TypingMessage.STOPPED_TYPING) {
      let currentTypingUsers = [...typingUsersRef.current];
      currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== frame.body.playerId);
      setTypingUsers(currentTypingUsers);
      setIsTyping("")

    } else if (frame.body.body === TypingMessage.IS_TYPING_PARTY) {
      if (frame.body.playerId !== playerIdReF.current) {
        setIsPartyTyping(handleIsTyping(frame.body.from, frame.body.timestamp, frame.body.playerId, partyTypingUsersRef.current, true))
      }
    } else if (frame.body.body === TypingMessage.STOPPED_TYPING_PARTY) {
      let currentTypingUsers = [...partyTypingUsersRef.current];
      currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== frame.body.playerId);
      setPartyTypingUsers(currentTypingUsers);
      setIsPartyTyping("")
    } else {
      handelMessageTypes(JSON.stringify(frame.body), from)
    }
  }


  //Check the typing array to see if the user typing is present, if so update if not add
  function handleIsTyping(from: string, timestamp: string, playerId: string, selectedArray: TypingUsers[], party: boolean) {
    let currentTypingUsers = [...selectedArray];
    const userIndex = currentTypingUsers.findIndex(userObj => userObj.user === from);
    if (userIndex !== -1) {
      currentTypingUsers[userIndex].timestamp = timestamp;
    } else {
      currentTypingUsers.push({ playerId: playerId, user: from, timestamp: timestamp });
    }

    if (party) {
      setPartyTypingUsers(currentTypingUsers)
    } else {
      setTypingUsers(currentTypingUsers);
    }

    return setTyping(selectedArray)

  }

  // Display typing status
  function setTyping(selectedArray: TypingUsers[]): string {
    if (selectedArray.length === 0) {
      return "";
    } else if (selectedArray.length === 1) {
      return `${selectedArray[0].user} is typing...`;
    } else {
      return "Multiple people are typing...";
    }
  }

  // handle messages types as the Room messages come in as an array and global messages do not
  function handelMessageTypes(message: any, type: string) {
    let data = JSON.parse(message)
    switch (type) {
      case "global":
        formatMessages(data)
        break;
      case "room":
        data.forEach((element: any) => {
          if (element.body === TypingMessage.IS_TYPING) {
            if (element.playerId !== playerIdReF.current) {
              setIsTyping(handleIsTyping(element.from, element.timestamp, element.playerId, typingUsersRef.current, false))
            }
          } else if (element.body === TypingMessage.STOPPED_TYPING) {
            let currentTypingUsers = [...typingUsersRef.current];
            currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== element.playerId);
            setTypingUsers(currentTypingUsers);
            setIsTyping("")

          } else if (element.body === TypingMessage.IS_TYPING_PARTY) {
            if (element.playerId !== playerIdReF.current) {
              setIsPartyTyping(handleIsTyping(element.from, element.timestamp, element.playerId, partyTypingUsersRef.current, true))
            }
          } else if (element.body === TypingMessage.STOPPED_TYPING_PARTY) {
            let currentTypingUsers = [...partyTypingUsersRef.current];
            currentTypingUsers = currentTypingUsers.filter(userObj => userObj.playerId !== element.playerId);
            setPartyTypingUsers(currentTypingUsers);
            setIsPartyTyping("")
          } else {
            formatMessages(element)
          }
        });
        break;
      default:
        console.log("Missing Message Type")
        break;
    }
  }

  // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
  function formatMessages(data: any) {
    let currentMessages: Array<any> = [...messageRef.current]
    currentMessages = addMessage(currentMessages, data, getAvatar)
    dispatch(setMessageHistory(currentMessages))
    newMessage()
  }

  // add the incoming messages to the message history state array and attach timestamp, only store up to 500 messages
  function formatPartyMessages(data: any) {
    let currentMessages: Array<any> = [...partyMessageRef.current]
    currentMessages = addPartyMessage(currentMessages, data, getAvatar, usersRef.current)
    dispatch(setPartyMessageHistory(currentMessages))
    newMessage()
  }

  const handleUserQueuePartyMessage = (message: any) => {
    let data = message.body;
    if (data.partyInfo) {
      handlePartyInfo(data);
    } else {
      handleTextMessage(data);
    }
    dispatch(setUpdate(!update));
  };

   // Function to handle party info messages
   const handlePartyInfo = (data: any) => {
    dispatch(setPartyInfo(data.partyInfo));
    if (data.partyInfo.partyPeople && data.partyInfo.partyPeople.length > 0) {
      handlePartyPeople(data);
    } else if (data.partyInfo.hostPlayerId === playerIdReF.current) {
      handleHostParty(data);
    }
  };

  // Function to handle party people processing
  const handlePartyPeople = (data: any) => {
    let player = data.partyInfo.partyPeople.find(
      (user: PartyPerson) => user.playerId === playerIdReF.current
    );

    if (player && player.joinStatus !== PartyPersonJoinStatus.JOINED && data.partyInfo.hostPlayerId !== playerIdReF.current) {
      dispatch(setUnityMessage(JSON.stringify({ type: "unityMessage", msg: data, responded: false })));
    } else {
      dispatch(setIsInParty(true))
    }

    if (data.textMessage.includes("You have been kicked from")) {
      dispatch(setUnityMessage(JSON.stringify({ type: "unityMessage", msg: data })));
    }

    let partyPeople = addPartyPeople(data, getAvatar, usersRef.current, partyUsersRef.current);

    // Check if the host is already in the party users
    let hostExists = partyUsersRef.current.some(
      (user: User) => user.playerId === data.partyInfo.hostPlayerId
    );

    if (!hostExists) {
      let host = addHost(data, getAvatar, usersRef.current);
      partyPeople = host.concat(partyPeople);
    }
    // Remove duplicate users based on playerId
    const uniquePartyPeople = removeDuplicatesByPlayerId(partyPeople);

    dispatch(setPartyUsers(uniquePartyPeople));
    formatPartyMessages(data);
  };

  // Function to handle host-specific scenario
  const handleHostParty = (data: any) => {
    let host = addHost(data, getAvatar, usersRef.current);
    dispatch(setPartyUsers(host));
    dispatch(setUpdate(!update));
    dispatch(setPartyChat(true));
    dispatch(setIsInParty(true));
    formatPartyMessages(data)
  };

  // Function to handle specific text messages
  const handleTextMessage = (data: any) => {
    if (data.textMessage.includes("has ended") || data.textMessage.includes("You have ended the party")) {
      dispatch(setPartyUsers([]));
      dispatch(setPartyChat(false));
      dispatch(setIsInParty(false));
    } else if (data.textMessage.includes("has accepted your request")) {
      dispatch(setPartyChat(true));
    } else if (data.textMessage.includes("You have been kicked from")) {
      dispatch(setPartyUsers([]));
      dispatch(setPartyChat(false));
      dispatch(setIsInParty(false));
      dispatch(setUnityMessage(JSON.stringify({ type: "unityMessage", msg: data })));
    }
  };
  return (
    <>
      <ChatWindow setIsFocused={setIsFocused} isTyping={isTyping} room={room} isPartyTyping={isPartyTyping} />
    </>
  )
}