import {useDispatch } from 'react-redux';
import ChatBubble, { Reaction } from "./chatWindowComponents/ChatBubble";
import ChatInput from "./ChatInput";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { UsersOnline } from './chatWindowComponents/UsersOnline';
import { setIsInParty, setUpdate } from '../../store/appUser';
import { setPartyChat } from '../../store/party';
import EmojiPicker, { Theme } from 'emoji-picker-react';
import { setMessageHistory } from '../../store/messages';
import { getTimestamp } from './Timestamp';
import { CustomEmojis } from './chatWindowComponents/customEmojis';

type Props = {
    setIsFocused: Function,
    isTyping: string,
    isPartyTyping: string,
    room: string
}


const ChatWindow: React.FC<Props> = ({ setIsFocused, isTyping, room, isPartyTyping}) => {

   
    const { messageHistory, partyMessageHistory } = useAppSelector((state) => state.messages)
    const { name } = useAppSelector(state => state.scene)
    const { player, update, isInParty } = useAppSelector(state => state.appUser)
    const { partyChat } = useAppSelector(state => state.party)
    const [isScrolledUp, setIsScrolledUp] = useState(false); 
    const [showEmojis, setShowEmojis] = useState(false)
    const [showReactionEmojis, setShowReactionEmojis] = useState(false)
    const [showScrollToBottom, setShowScrollToBottom] = useState(false); 
    const [selectedMessage, setSelectedMessage] = useState<string | null>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef(messageHistory);
    const playerRef = useRef(player)
    const selectedMessageRef = useRef(selectedMessage)
    const dispatch= useAppDispatch()


    useEffect(() => {
        playerRef.current = player
        selectedMessageRef.current = selectedMessage
    }, [player, selectedMessage])
    // Scroll to bottom when new message is received
    const scrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
            setShowScrollToBottom(false); 
        }
    };


    useEffect(() => {
        if (!isScrolledUp) {
            scrollToBottom(); // Only auto-scroll if the user hasn't scrolled up
        } else {
            setShowScrollToBottom(true); // Show the scroll to bottom icon when a new message arrives
        }
    }, [messageHistory, partyMessageHistory])

    // Update Refs
    useEffect(() => {
        if(!partyChat){
            messageRef.current = messageHistory
        }        
    }, [messageHistory])

    useEffect(() => {
        if(partyChat){
            messageRef.current = partyMessageHistory
        }
        
    }, [partyMessageHistory])

     // Detect if user has scrolled up
     const handleScroll = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            const isAtBottom = chatContainer.scrollHeight - chatContainer.scrollTop === chatContainer.clientHeight;
            const threshold = 500; // Distance from the bottom to consider the user has scrolled up
            setIsScrolledUp(chatContainer.scrollHeight - chatContainer.scrollTop > chatContainer.clientHeight + threshold);
            // If the user is at the bottom, hide the "scroll to bottom" icon
            if (isAtBottom) {
                setShowScrollToBottom(false);
            }
        }
    };

    //Handle inbound messages
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            const message = event.data;
            if (message === 'Chat Opened') {
                scrollToBottom()
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (partyChat) {
            messageRef.current = partyMessageHistory;
        } else {
            messageRef.current = messageHistory;
        }
    }, [partyChat]);
    
    
    function sendMessageToParent(msg: string) {
        window.parent.postMessage(msg, window.location.origin);
    }

    function toggleDefaultAndPartyChat(){
        if(!partyChat){
            messageRef.current = partyMessageHistory
            dispatch(setPartyChat(true))
        } else {
            messageRef.current = messageHistory
            dispatch(setPartyChat(false))
        }
        dispatch(setUpdate(!update))  
    }

    function turnOnPartyChat(){
        messageRef.current = partyMessageHistory
        dispatch(setPartyChat(true))
        dispatch(setUpdate(!update))  
    }


    function addReaction(id: string, reaction: string) {
        let currentMessages: Array<any> = [...messageRef.current];
        let player = playerRef.current
        const messageIndex = currentMessages.findIndex(message => message.id === id);

        if (messageIndex !== -1) {
            const message = { ...currentMessages[messageIndex] };
            const reactions = [...message.reactions];
            const existingReactionIndex = reactions.findIndex(reaction => reaction.playerId === player.playerId);

            if (existingReactionIndex !== -1) {
                reactions[existingReactionIndex] = {
                    ...reactions[existingReactionIndex],
                    emoji: reaction,
                };
            } else {
                const newReaction: Reaction = {
                    playerId: player.playerId,
                    displayName: player.displayName,
                    emoji: reaction,
                };
                reactions.push(newReaction);
            }
            message.reactions = reactions;
            currentMessages[messageIndex] = message;
            dispatch(setMessageHistory(currentMessages))
            sendReactionMessage(id, player.playerId, player.displayName, reaction)
        }
    }

    function sendReactionMessage(messageId: string, playerId: string, displayName: string | null, emoji: string) {
        let reactionMessage = {
            playerId: playerId,
            displayName: displayName,
            messageId: messageId,
            timestamp: getTimestamp(),
            emoji: emoji
        }
        try {
            window.STOMP.send("/app/send-reaction-message", reactionMessage);
        } catch (error) {
            console.log(error)
        }
    }


    const addEmoji = (emoji: { imageUrl: string }) => {
        console.log(emoji)
        let id = selectedMessageRef.current
        console.log(id)
        if(id){
            setShowReactionEmojis(false)
            addReaction(id, emoji.imageUrl)
        }
    };


    return (
        <div className='chat-body' onClick={() => {
            setShowReactionEmojis(false)
            setShowEmojis(false)}}>
            <div className='chat-viewport-container'>
            <div className={`chat-header-container ${isInParty ? "can-click " : ""}`} onClick={() => isInParty ? toggleDefaultAndPartyChat() : console.log("Not in Party")}>
                <h3 className="chat-pill" >{partyChat ? "Party": "Chat"}</h3>
                <h2 className="studio-title"><span style={{fontWeight: 'bold'}}>{name}</span></h2>
            </div>
            <div className='users-online'>
                <UsersOnline />
            </div>
            <div className="chat-container" ref={chatContainerRef} onScroll={handleScroll} >
                <ul>
                    {
                        messageRef.current.map((msg, index) => {
                            const lastMessageSameUser = index > 0 && messageRef.current[index - 1].playerId === msg.playerId;
                            const nextMessageSameUser = index < messageRef.current.length - 1 && messageRef.current[index + 1].playerId === msg.playerId;
                            const isFirstMessage = !lastMessageSameUser;
                            const isLastMessage = !nextMessageSameUser;
                            
                            return (
                                <ChatBubble
                                    key={index + "bubble"}
                                    index={index}
                                    msg={msg}
                                    lastMessageSameUser={lastMessageSameUser}
                                    isFirstMessage={isFirstMessage}
                                    isLastMessage={isLastMessage}
                                    togglePartyChat={turnOnPartyChat}
                                    selectedMessage={selectedMessage}
                                    setSelectedMessage={setSelectedMessage}
                                    setShowReactionEmojis={setShowReactionEmojis}
                                    showReactionEmojis={showReactionEmojis}
                                />
                            );
                        })
                    }
                </ul>


            </div>
            {/* Display the scroll to bottom icon */}
            {showScrollToBottom && (
                <div className="scroll-to-bottom-div">
                    <button className="scroll-to-bottom" onClick={scrollToBottom}>
                     ↓ New Messages
                    </button>
           
                    </div>
               )} 
            {
             partyChat ? <div className='is-typing-container'>{isPartyTyping}</div> : <div className='is-typing-container'>{isTyping}</div>
            }
            <div className='emoji-picker-reaction' onClick={(e) => e.stopPropagation()} >
                    <EmojiPicker 
                        onEmojiClick={(emoji: any) => addEmoji(emoji)} 
                        reactionsDefaultOpen={true} 
                        onReactionClick={(emoji: any) => addEmoji(emoji)} 
                        theme={Theme.DARK}
                        open={showReactionEmojis}
                        customEmojis={CustomEmojis}/>
            </div>
            
            <ChatInput sendMessageToParent={sendMessageToParent} setIsFocused={setIsFocused} room={room} showEmojis={showEmojis} setShowEmojis={setShowEmojis}/>
          
        </div>
        </div>
    )
}

export default ChatWindow;
