import { Route, Routes} from 'react-router-dom';
import './App.css';
import { Fragment, useEffect } from "react";
import { UnityClient } from './components/UnityClient';
import { InboundLinkRouting } from './components/InboundLinkRouting';
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import WelcomePage from './components/LandingPage/WelcomePage';
import { AvatarPage } from './components/AvatarPage/AvatarPage';
import { useAppSelector } from './store/hooks';
import { SearchPage } from './components/LandingPage/SearchPage';
import { LandingPage } from './components/LandingPage/LandingPage';
import { FAQPage } from './components/FAQ/FAQPage';
import { Footer } from './components/LandingPage/Footer';
import { AboutPage } from './components/About/AboutPage';
import { ScenePage } from './components/ScenePages/ScenePage';

const App: React.FC = () => {
  
  const {scenes} = useAppSelector(state => state.scene)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  
  
  return (
      <Fragment>
                <Routes> 
                    <Route
                      path="/"
                      element={
                          <LandingPage /> }
                    />
                    <Route
                      path="/home"
                      element={
                        <WelcomePage /> }
                    />
                    <Route
                      path="/:id"
                      element={
                        <WelcomePage /> }
                    />
                    <Route
                      path="/about"
                      element={
                      <><AboutPage /><Footer additionalClasses={'about-footer'} home={true}/></> }
                    />
                     <Route
                      path="/FAQ"
                      element={
                      <><FAQPage /><Footer additionalClasses={'faq-footer'} home={true} /></> }
                    />
                    {
                      scenes.map(scene => {
                        return <Route 
                                path={`/mv/live/${scene.className}`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={true}/>
                          }
                        />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                path={`${scene.url}`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={false}/>
                                }
                                />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                path={`${scene.url}/:id`}
                                element={
                                  <InboundLinkRouting scene={scene} scenePage={false}/>
                                }
                                />
                      })
                    }
                    {
                      scenes.map(scene => {
                        return <Route
                                path={`${scene.infoPageUrl}`}
                                element={
                                  <ScenePage scene={scene}/>
                                }
                                />
                      })
                    }
                    <Route
                      path="/mv/rpm"
                      element={
                        <ReadyPlayerMeCreator
                          style={{ width: "100vw", height: "100vh", border: "none", margin: 0, zIndex: 4500000, borderRadius: 'none' }}
                          width='100vw'
                          height='100vh'
                          close={null}
                          isModal={false}
                          sendMessage={null}
                        />
                      }
                    />
                    <Route
                      path="/search"
                      element={
                        <SearchPage />
                      }
                      />
                </Routes>
        </Fragment>
  );
}

export default App;
